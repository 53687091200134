import { Button, Image } from "antd"
import { useState } from "react"
import apiService from "services/apiService"
import { notifyToast } from "services/notifyToast"

export default function LinkedinAuth() {
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    try {
      setLoading(true)
      const { data } = await apiService.get("/api/auth/linkedin/link")
      window.location.href = data.data.link
    } catch (e) {
      await notifyToast(e)
    }

    setLoading(false)
  }

  return (
    <Button
      type="ghost"
      className="p-0 my-0 mx-4"
      style={{ minHeight: 37, height: 37 }}
      disabled={loading}
      onClick={onSubmit}
    >
      <Image
        alt="Linkedin"
        width={35}
        height={35}
        preview={false}
        src="/assets/icons/linkedIn.png"
      />
    </Button>
  )
}
