import { Button, Image } from "antd"
import { notifyToast } from "services/notifyToast"

export default function GoogleAuth() {
  const onSubmit = async () => {
    try {
      window.location.href = `${process.env.NEXT_PUBLIC_BASE_URL}/api/auth/google/link`
    } catch (e) {
      await notifyToast(e)
    }
  }

  return (
    <Button
      type="ghost"
      className="p-0 my-0 mx-4"
      style={{ minHeight: 37, height: 37 }}
      onClick={onSubmit}
    >
      <Image
        alt="Google"
        width={35}
        height={35}
        preview={false}
        src="/assets/icons/google.png"
      />
    </Button>
  )
}
