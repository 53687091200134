import { Col, Row } from "antd"
import GoogleAuth from "components/Auth/GoogleAuth"
import LinkedinAuth from "components/Auth/LinkedinAuth"
import CPButton from "components/CP/CPButton"
import CPInput from "components/CP/CPInput"
import { NextSeo } from "next-seo"
import Link from "next/link"
import { useRouter } from "next/router"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import apiService from "services/apiService"
import { notifyToast, notifyUser } from "services/notifyToast"
import { getProfile } from "states/slices/profileReducer"
import { getItem, setItem } from "utils/storage"

const formRules = {
  email: {
    required: "Email is required",
  },
  password: {
    required: "Password is required",
  },
}

export default function Login() {
  const { errors, control, setError, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  })
  const router = useRouter()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const handleNoProfileTypeSelected = resData => {
    if (resData?.data?.access_token) {
      setItem("access_token", resData?.data?.access_token)
    }
    if (resData?.data?.user) {
      setItem("user_info", resData?.data?.user)
    }

    return router.push(
      `/signup/step3?a=${window?.btoa(
        JSON.stringify(resData?.data?.access_token)
      )}`
    )
  }

  const handleSuccessLogin = async res => {
    if (res?.data?.access_token) {
      setItem("access_token", res.data.access_token)
    }

    await dispatch(getProfile())

    if (res?.data?.user) {
      setItem("user_info", res?.data?.user)
    }

    if (router.query?.back) {
      await router.push(String(router.query.back))
      return
    }

    const invitationToken = getItem("inv_token")
    if (invitationToken) {
      const id = getItem("inv_id")
      const type = getItem("inv_type")

      notifyUser({
        title: "Login",
        type: "success",
        description: "Welcome to Birdflocks",
      })

      await router.push(`/join/${type}/${id}?token=${invitationToken}`)
      return
    }

    try {
      const _hsq = (window._hsq = window._hsq || [])
      const userData = { ...res?.data?.user }

      _hsq.push([
        "identify",
        {
          id: userData.id,
          email: userData.email,
          username: userData.username,
          first_name: userData.first_name,
          last_name: userData.last_name,
        },
      ])
    } catch (e) {
      console.log(e)
    }

    const user = res?.data?.user
    notifyUser({
      title: "Login",
      type: "success",
      description: "Welcome to Birdflocks",
    })

    if (user?.username) {
      if (user?.profile_type === 1) {
        await router.push(`/profile/${user?.username}`)
        return
      }

      await router.push(`/company/${user?.username}`)
      return
    }

    await router.push("/")
  }

  const handleErrorEmailNotVerified = resData => {
    if (resData?.errors?.data?.email && process.browser && window) {
      return (location.href = `/signup/step2?q=${window?.btoa(
        resData?.errors?.data?.email
      )}`)
    }
    return (location.href = "/signup/step2")
  }

  const handleOtherErrorMessages = resData => {
    Object.entries(resData?.errors?.messages || {}).forEach(([key, value]) => {
      if (!(key && value)) {
        return
      }

      setError(key, {
        type: "manual",
        message: value[0],
      })
    })
  }

  const handleLoginResponse = async res => {
    if (
      res.status === 200 &&
      res?.data?.data?.access_token &&
      res?.data?.data?.profile_type === null
    ) {
      await handleNoProfileTypeSelected(res?.data)
    } else if (res.status === 200 && res?.data?.data?.access_token) {
      await handleSuccessLogin(res?.data)
    } else if (
      res.status === 401 &&
      res?.data?.errors?.messages === "Email is not verified"
    ) {
      handleErrorEmailNotVerified(res?.data)
    } else if (
      res?.data?.errors?.messages &&
      res?.data?.errors?.messages.constructor === Object
    ) {
      handleOtherErrorMessages(res?.data)
    }
  }

  const onSubmit = async values => {
    try {
      const body = {
        email: values?.email,
        password: values?.password,
      }
      setLoading(true)
      const res = await apiService.post("/api/auth/login", body)
      handleLoginResponse(res)
    } catch (e) {
      setLoading(false)
      await notifyToast(e)
    }
  }

  const form = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={[15, 15]}>
        <Col xs={24}>
          <Controller
            render={({ field: { onChange, onBlur, value } }) => (
              <CPInput
                name="email"
                type="email"
                placeholder="Email"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                labelconfig={{
                  error: errors?.email?.message || null,
                  required: true,
                }}
              />
            )}
            name="email"
            control={control}
            rules={formRules?.email}
          />
        </Col>
        <Col xs={24}>
          <Controller
            render={({ field: { onChange, onBlur, value } }) => (
              <CPInput
                type="password"
                placeholder="Password"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                labelconfig={{
                  error: errors?.password?.message || null,
                  required: true,
                }}
              />
            )}
            name="password"
            control={control}
            rules={formRules?.password}
          />
        </Col>
      </Row>
      <br />
      <CPButton type="primary" htmlType="submit" loading={loading} block>
        Login
      </CPButton>
    </form>
  )

  return (
    <>
      <NextSeo
        title="Login | Birdflocks"
        openGraph={{
          title: "Login | Birdflocks",
          site_name: "Login | Birdflocks",
        }}
      />
      <Row
        className="bg-cover bg-center mh-screen-footer"
        style={{ backgroundImage: "url('/assets/login/bg.png')" }}
      >
        <Col
          xs={{ span: 22, offset: 1 }}
          md={{ span: 18, offset: 3 }}
          lg={{ span: 10, offset: 12 }}
          xl={{ span: 9, offset: 12 }}
          xxl={{ span: 5, offset: 14 }}
        >
          <div className="mt-4 sm:mt-12 md:mb-36 sm:mb-12 mb-8 py-8 px-5 bg-white shadow-xl rounded-lg">
            <h1 className="text-2xl font-bold text-gray-800 text-center mb-5">
              Welcome Back!
            </h1>
            <img
              src="/assets/login/avatar.png"
              alt="avatar"
              className="mx-auto h-32 mb-3"
            />

            {form}

            <div className="mt-6 text-gray-400 px-1">
              <div>
                <Link
                  passHref
                  prefetch={false}
                  href="/forgot-password"
                  className="text-primary"
                >
                  Forgot Password?
                </Link>
              </div>
              <div className="mt-3">
                <span>New to Birdflocks?</span>
                <Link
                  passHref
                  prefetch={false}
                  href="/signup"
                  className="ml-2 text-primary"
                >
                  Join Now
                </Link>
              </div>
            </div>

            <div className="flex justify-center items-center text-gray-400 mt-8 mb-6 f-13">
              <div className="border-t-2 border-gray-200 flex-grow" />
              <span className="inline-block mx-4">or login with</span>
              <div className="border-t-2 border-gray-200 flex-grow" />
            </div>

            <div className="flex justify-center">
              <LinkedinAuth />
              <GoogleAuth />
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}
